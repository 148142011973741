import type {PageProps} from 'gatsby'
import {graphql} from 'gatsby'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import React from 'react'

import {FigmaLayout} from '@/components/layout/figma-layout'
import type {GetPageQuery} from '@/graphql-types'

const MDXRendererLayout = (props: {data: GetPageQuery} & PageProps) => (
    <FigmaLayout
        {...props}
        pageContext={{
            frontmatter: props.data.mdx.frontmatter
        }}
    >
        <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
    </FigmaLayout>
)

export const query = graphql`
    query getFigmaPage($id: String!) {
        mdx(id: {eq: $id}) {
            frontmatter {
                title
                status
                editPageUrl
                intro
                storybookUrl
            }
            body
        }
    }
`

export default MDXRendererLayout
